import React, { useEffect, useState } from 'react'
import axios from 'axios'
import SubjectUnits from './SubjectUnits'
import './MyGrades.css'
import RenderCircle from './RenderCircle'

// Import feedback messages
import feedbackMessages from './feedbackMessages.json'

// Import icons from react-icons
import { FaTrophy, FaExclamationTriangle } from 'react-icons/fa'

const CACHE_KEY = 'myGrades.data.'
const CACHE_TIMESTAMP_KEY = 'myGrades.cacheTimestamp.'

const MyGrades = ({ showSubjectUnits, setShowSubjectUnits, userId }) => {
  const [grades, setGrades] = useState(null)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [feedbackIcon, setFeedbackIcon] = useState(null)

  useEffect(() => {
    const fetchGrades = async () => {
      const cacheExpirationMinutes =
        parseInt(process.env.REACT_APP_CACHE_EXPIRATION_MINUTES, 10) || 15
      const now = new Date().getTime()

      // Update keys
      const cacheKey = CACHE_KEY + userId
      const cacheTSKey = CACHE_TIMESTAMP_KEY + userId

      // Check local storage for cached data
      const cachedData = localStorage.getItem(cacheKey)
      const cachedTimestamp = localStorage.getItem(cacheTSKey)

      if (cachedData && cachedTimestamp) {
        const elapsedMinutes =
          (now - parseInt(cachedTimestamp, 10)) / (1000 * 60)

        // Use cached data if within expiration time
        if (elapsedMinutes < cacheExpirationMinutes) {
          setGrades(JSON.parse(cachedData))
          localStorage.setItem(
            'userProgress.averageScore',
            JSON.parse(cachedData).average_score
          )
          return
        }
      }
      // Fetch from server and update cache
      try {
        const response = await axios.get(
          `https://ush.physicsinseconds.com/student/aggregate/${userId}`
        )
        setGrades(response.data)
        localStorage.setItem(
          'userProgress.averageScore',
          response.data.average_score
        )
        // Store response and timestamp in local storage
        localStorage.setItem(cacheKey, JSON.stringify(response.data))
        localStorage.setItem(cacheTSKey, now.toString())
        // console.log(response.data.average_score)
      } catch (error) {
        console.error('Error fetching grades:', error)
      }
    }

    fetchGrades()
  }, [userId])

  useEffect(() => {
    if (grades) {
      const { average_score } = grades

      // Randomly select a message when grades or average_score changes
      let randomMessage = ''
      let icon = null
      if (average_score >= 95) {
        // Select random feedback from "above95" category
        const randomIndex = Math.floor(
          Math.random() * feedbackMessages.above95.length
        )
        randomMessage = feedbackMessages.above95[randomIndex]
        icon = <FaTrophy size={30} color='gold' /> // Trophy icon for high scores
      } else {
        // Select random feedback from "below95" category
        const randomIndex = Math.floor(
          Math.random() * feedbackMessages.below95.length
        )
        randomMessage = feedbackMessages.below95[randomIndex]
        icon = <FaExclamationTriangle size={30} color='red' /> // Warning icon for below 95%
      }
      localStorage.setItem('userProgress.feedbackMessage', randomMessage)
      setFeedbackMessage(randomMessage)
      setFeedbackIcon(icon)
    }
  }, [grades]) // Re-run this effect whenever grades are fetched or updated

  const handleSubjectClick = (subject, score, color) => {
    setShowSubjectUnits({
      flag: true,
      subject: subject,
      score: score,
      color: color,
    })
  }

  if (!grades) {
    return <div>Loading...</div>
  }

  const { average_score, aggregates } = grades
  const subjectCount = Object.keys(aggregates).length

  // Extract "flp" subject if it exists
  const flpSubject = aggregates['flp'] ? { flp: aggregates['flp'] } : null

  // Remove "flp" from aggregates if it exists
  const remainingAggregates = flpSubject
    ? Object.keys(aggregates)
        .filter((subject) => subject !== 'flp')
        .reduce((acc, key) => {
          acc[key] = aggregates[key]
          return acc
        }, {})
    : aggregates

  // Sort the remaining subjects in ascending order
  const sortedRemainingSubjects = Object.keys(remainingAggregates).sort()

  return (
    <div className='grades-container'>
      {showSubjectUnits.flag ? (
        <SubjectUnits
          subject={showSubjectUnits.subject}
          userId={userId}
          score={showSubjectUnits.score}
          color={showSubjectUnits.color}
          showSubjectUnits={showSubjectUnits}
          setShowSubjectUnits={setShowSubjectUnits}
        />
      ) : (
        <>
          {/* Feedback Panel */}
          <div className='feedback-panel'>
            <div className='feedback-icon'>{feedbackIcon}</div>
            <p>{feedbackMessage}</p>
          </div>
          {/* Conditionally render the overall circle */}
          {subjectCount > 1 && (
            <div className='circle overall-score'>
              {
                <RenderCircle
                  percentage={average_score}
                  color={'#2196f3'}
                  size={150} // Larger size for "Overall"
                />
              }
              <div className='circle-text'>{average_score}%</div>
              <div className='circle-label'>Overall</div>
            </div>
          )}

          <div className='subjects'>
            {/* Render remaining subjects in ascending order */}
            {sortedRemainingSubjects.map((subject) => (
              <div
                className='subject-circle-container'
                key={subject}
                onClick={() => {
                  const subjectColor = getComputedStyle(
                    document.querySelector(`.${subject.toLowerCase()}-score`)
                  )
                    .getPropertyValue(`--${subject.toLowerCase()}-color`)
                    .trim()
                  handleSubjectClick(
                    subject,
                    remainingAggregates[subject].average_score,
                    subjectColor
                  )
                }}
              >
                <div className={`circle subject-score ${subject}-score`}>
                  {
                    <RenderCircle
                      percentage={remainingAggregates[subject].average_score}
                      color={`var(--${subject}-color)`}
                      size={120} // Default size for subjects
                    />
                  }
                  <div className='circle-text'>
                    {remainingAggregates[subject].average_score}%
                  </div>
                </div>
                <div className='subject-label'>
                  {subject === 'logical' ? 'Logical Reasoning' : subject}
                </div>
              </div>
            ))}

            {/* Render the "flp" subject last */}
            {flpSubject && (
              <div
                className='subject-circle-container'
                key='flp'
                onClick={() => {
                  const subjectColor = getComputedStyle(
                    document.querySelector(`.flp-score`)
                  )
                    .getPropertyValue(`--flp-color`)
                    .trim()
                  handleSubjectClick(
                    'flp',
                    flpSubject.flp.average_score,
                    subjectColor
                  )
                }}
              >
                <div className={`circle subject-score flp-score`}>
                  {
                    <RenderCircle
                      percentage={flpSubject.flp.average_score}
                      color={`var(--flp-color)`}
                      size={120} // Default size for "flp"
                    />
                  }
                  <div className='circle-text'>
                    {flpSubject.flp.average_score}%
                  </div>
                </div>
                <div className='subject-label'>flp</div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default MyGrades
