import React, { useEffect, useRef, useState } from 'react'

const RenderCircle = ({ percentage, color, size: propSize }) => {
  const svgRef = useRef(null)
  const [size, setSize] = useState(propSize || 120) // Use propSize if provided, otherwise default to 120

  useEffect(() => {
    // Update the size based on the parent container's width if propSize is not provided
    if (!propSize) {
      const updateSize = () => {
        if (svgRef.current) {
          const containerWidth = svgRef.current.parentElement.offsetWidth
          setSize(containerWidth * 0.8) // Dynamically adjust size to 80% of the container's width
        }
      }

      updateSize() // Initialize size
      window.addEventListener('resize', updateSize) // Update size on window resize

      return () => {
        window.removeEventListener('resize', updateSize)
      }
    }
  }, [propSize])

  const radius = size * 0.45 // Set radius as 45% of the size
  const strokeWidth = size * 0.08 // Set stroke width as 8% of the size
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (percentage / 100) * circumference

  return (
    <svg
      ref={svgRef}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
    >
      {/* Background circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke='#e0e0e0'
        strokeWidth={strokeWidth}
        fill='none'
      />
      {/* Foreground (progress) circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={color}
        strokeWidth={strokeWidth}
        fill='none'
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
    </svg>
  )
}

export default RenderCircle
