import React from 'react'

const Help = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: '#333' }}>Help</h2>
      <p>
        Welcome to the Help Center! Below are quick descriptions of the sections
        in the platform:
      </p>
      <ul
        style={{
          listStyleType: 'disc',
          paddingLeft: '20px',
          lineHeight: '1.8',
        }}
      >
        <li>
          <strong>Home:</strong> Explore the latest updates and features on the
          platform.
        </li>
        <li>
          <strong>Ask Ustaad Jee:</strong> Seek personalized advice or guidance
          from our experts.
        </li>
        <li>
          <strong>Live Classes:</strong> Access live sessions to enhance your
          learning experience. * Eligible sessions only.
        </li>
        <li>
          <strong>LMS:</strong> Manage your learning materials and track
          progress efficiently.
        </li>
        <li>
          <strong>My Grades:</strong> View and analyze your academic performance
          in detail.
        </li>
        <li>
          <strong>Service Request:</strong> Manage and track requests for
          additional services, such as installment payments, enrollment in
          add-on, or new courses.
        </li>
      </ul>
      <p>
        For more questions, contact us via{' '}
        <a
          href='https://api.whatsapp.com/send/?phone=923334999910&text&type=phone_number&app_absent=0'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            color: '#25D366',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          WhatsApp Support
        </a>
        .
      </p>
    </div>
  )
}

export default Help
