import React, { useEffect, useState } from 'react'
import './App.css'
import MyGrades from './components/MyGrades'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import Home from './components/Home'
import Help from './components/Help'

const CACHE_KEY = 'student.data.'
const CACHE_TIMESTAMP_KEY = 'student.cacheTimestamp.'

function App() {
  const [selectedContent, setSelectedContent] = useState('Home')
  const [showSubjectUnits, setShowSubjectUnits] = useState({
    flag: false,
    subject: '',
    score: 0,
    color: '',
  })
  const [hideNonSelected, setHideNonSelected] = useState(true)
  const [validUser, setValidUser] = useState(false)
  const [user, setUser] = useState({
    studentName: '',
    email: '',
    picture: '',
    id: '',
    lw_id: '',
    status: '',
  })
  const [msg, setMsg] = useState('')
  const [showMyGrades, setShowMyGrades] = useState(false)

  const handleLinkClick = (content) => {
    if (content === 'My Grades' || content === 'Home') {
      setShowSubjectUnits({ flag: false, subject: '' })
      setShowMyGrades(false)
    }
    setSelectedContent(content)
    setHideNonSelected(true) // Automatically hide non-active options
  }

  const toggleHideNonSelected = () => {
    setHideNonSelected((prev) => !prev)
  }

  function isEligible() {
    let isEligible = false

    if (
      user.session?.toLowerCase().includes('- june -') ||
      user.session?.toLowerCase().includes('- apr -')
    ) {
      return isEligible
    }

    if (
      user.status === 'Active' &&
      (user.session?.toLowerCase().includes('subject') ||
        user.session?.toLowerCase().includes('advance') ||
        user.session?.toLowerCase().includes('standard') ||
        user.session?.toLowerCase().includes('premium'))
    ) {
      isEligible = true
    }
    return isEligible
  }

  const handleCallbackResponse = (response) => {
    const userObject = jwt_decode(response.credential)
    document.getElementById('signInDiv').hidden = true

    if (userObject.email !== '') {
      const cacheExpirationMinutes =
        parseInt(process.env.REACT_APP_SMS_CACHE_EXPIRATION_MINUTES, 10) || 360
      const now = new Date().getTime()

      // Update keys
      const cacheKey = CACHE_KEY + userObject.email
      const cacheTSKey = CACHE_TIMESTAMP_KEY + userObject.email

      // Check local storage for cached data
      const cachedData = localStorage.getItem(cacheKey)
      const cachedTimestamp = localStorage.getItem(cacheTSKey)

      if (cachedData && cachedTimestamp) {
        const elapsedMinutes =
          (now - parseInt(cachedTimestamp, 10)) / (1000 * 60)

        // Use cached data if within expiration time
        if (elapsedMinutes < cacheExpirationMinutes) {
          setValidUser(true)
          setUser(JSON.parse(cachedData))
          setMsg('')
          return
        }
      }
      // Fetch from server and update cache
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/students?populate=*&filters[emailAddress][$eq]=${userObject.email}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_WEB_TOKEN}`,
        },
      })
        .then((response) => {
          if (response.data.meta.pagination.total !== 0) {
            setValidUser(true)
            // console.log(response.data.data[0].attributes.Status)
            setUser({
              studentName: userObject.name,
              email: userObject.email,
              picture: userObject.picture,
              id: response.data.data[0].id,
              lw_id: response.data.data[0].attributes.lw.userId,
              status: response.data.data[0].attributes.Status,
              session: response.data.data[0].attributes.session,
            })
            setMsg('')
            // Store response and timestamp in local storage
            localStorage.setItem(
              cacheKey,
              JSON.stringify({
                studentName: userObject.name,
                email: userObject.email,
                picture: userObject.picture,
                id: response.data.data[0].id,
                lw_id: response.data.data[0].attributes.lw.userId,
                status: response.data.data[0].attributes.Status,
                session: response.data.data[0].attributes.session,
              })
            )
            localStorage.setItem(cacheTSKey, now.toString())
          } else {
            setMsg(`Unknown user: ${userObject.email}`)
            document.getElementById('signInDiv').hidden = false
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) return resolve()
      const script = document.createElement('script')
      script.src = src
      script.onload = () => resolve()
      script.onerror = (err) => reject(err)
      document.body.appendChild(script)
    })

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'

    loadScript(src)
      .then(() => {
        /* global google */
        google.accounts.id.initialize({
          client_id:
            '560202696816-8i4n33ilj8emosrvjiu4655q9gv7lmv6.apps.googleusercontent.com',
          callback: handleCallbackResponse,
        })
        google.accounts.id.renderButton(document.getElementById('signInDiv'), {
          theme: 'filled_blue',
          size: 'large',
          shape: 'pill',
        })
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  useEffect(() => {
    if (selectedContent === 'Ask Ustaad Jee') {
      window.open('https://physicsinseconds.com/social', '_blank')
    } else if (selectedContent === 'Live Classes') {
      window.open('https://live.physicsinseconds.com', '_blank')
    } else if (selectedContent === 'Service Request') {
      window.open('https://admission.physicsinseconds.com', '_blank')
    }
  }, [selectedContent])

  function handleSignOut(event) {
    setValidUser(false)
    setMsg('')
    document.getElementById('signInDiv').hidden = false
    setUser({
      studentName: '',
      email: '',
      picture: '',
      id: '',
      lw_id: '',
      status: '',
    })
  }

  return (
    <div className='App'>
      <div align='center' style={{ display: 'grid' }}>
        <div align='center' style={{ display: 'grid' }}>
          <img
            src='https://sms.physicsinseconds.com/uploads/My_Ustaad_Jee_1_48ddf09817.jpg'
            style={{
              width: '100%',
            }}
            alt=''
          />
        </div>
        <article>
          <div id='signInDiv' style={{ marginTop: '5px' }}></div>
        </article>
      </div>

      {!validUser && (
        <div style={{ textAlign: 'center', margin: '20px', color: 'orange' }}>
          <h3>Sign in to continue to My Ustaad Jee!</h3>
          {msg && <p style={{ color: 'red' }}>{msg}</p>}
        </div>
      )}
      {validUser && (
        <div className='main-container'>
          <div className='left-panel'>
            <figure>
              <figcaption
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '15px', // Optional: adds space between elements
                  whiteSpace: 'nowrap', // Prevents text from wrapping
                }}
              >
                <p
                  style={{
                    color: '#eb5307',
                    margin: 0,
                    fontSize: '14px',
                    // overflow: 'hidden', // Ensures text doesn't overflow the container
                    textOverflow: 'ellipsis', // Adds "..." if text overflows
                    maxWidth: '150px', // Set a max width for the paragraph
                  }}
                >
                  UJS-{user.id}
                </p>
                <button
                  style={{
                    background: 'green',
                    color: 'white',
                    cursor: 'pointer',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '4px',
                  }}
                  className='myButton'
                  onClick={(e) => handleSignOut(e)}
                >
                  Sign Out
                </button>
              </figcaption>
            </figure>

            <button
              onClick={toggleHideNonSelected}
              style={{
                width: '90%',
                margin: '10px auto',
                display: 'block',
                padding: '10px',
                backgroundColor: '#444',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              {hideNonSelected ? 'Show All Options' : 'Hide Other Options'}
            </button>
            <ul>
              <li
                onClick={() => handleLinkClick('Home')}
                // style={{
                //   display:
                //     hideNonSelected && selectedContent !== 'Home'
                //       ? 'none'
                //       : 'block',
                // }}
              >
                Home
              </li>
              <li
                onClick={() => handleLinkClick('Ask Ustaad Jee')}
                style={{
                  display:
                    hideNonSelected && selectedContent !== 'Ask Ustaad Jee'
                      ? 'none'
                      : 'block',
                }}
              >
                Ask Ustaad Jee
              </li>
              <li
                onClick={() => handleLinkClick('Live Classes')}
                style={{
                  display:
                    hideNonSelected && selectedContent !== 'Live Classes'
                      ? 'none'
                      : 'block',
                }}
              >
                Live Classes
              </li>
              <li
                onClick={() => handleLinkClick('LMS')}
                style={{
                  display:
                    hideNonSelected && selectedContent !== 'LMS'
                      ? 'none'
                      : 'block',
                }}
              >
                LMS
              </li>
              <li
                onClick={() => handleLinkClick('My Grades')}
                // style={{
                //   display:
                //     hideNonSelected && selectedContent !== 'My Grades'
                //       ? 'none'
                //       : 'block',
                // }}
              >
                My Grades
              </li>
              <li
                onClick={() => handleLinkClick('Service Request')}
                style={{
                  display:
                    hideNonSelected && selectedContent !== 'Service Request'
                      ? 'none'
                      : 'block',
                }}
              >
                Service Request
              </li>
              <li
                onClick={() => handleLinkClick('Help')}
                style={{
                  display:
                    hideNonSelected && selectedContent !== 'Help'
                      ? 'none'
                      : 'block',
                }}
              >
                Help
              </li>
            </ul>
          </div>
          <div className='right-panel'>
            {selectedContent === 'My Grades' && isEligible() && (
              <MyGrades
                showSubjectUnits={showSubjectUnits}
                setShowSubjectUnits={setShowSubjectUnits}
                userId={user.lw_id}
              />
            )}
            {selectedContent === 'My Grades' && !isEligible() && (
              <>
                <h2>{selectedContent}</h2>
                <p>Not eligible for this service!</p>
                <p>
                  UJS-{user.id} ~ {user.session} ~ {user.status}
                </p>
              </>
            )}
            {(selectedContent === 'Ask Ustaad Jee' ||
              selectedContent === 'Live Classes' ||
              selectedContent === 'Service Request') && (
              <>
                <h2>{selectedContent}</h2>
                <p>{selectedContent} - Opened in a new tab!</p>
              </>
            )}
            {selectedContent === 'LMS' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                    margin: '30px', // Remove any default margin
                    padding: 0, // Remove any padding from the container
                    flexGrow: 1, // Ensure it takes available space within the parent
                  }}
                >
                  <button
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/us/app/ustaad-jee/id6449451519?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6449451519',
                        '_blank'
                      )
                    }
                    style={{
                      backgroundColor: '#007aff',
                      color: '#fff',
                      padding: '20px 30px',
                      fontSize: '18px',
                      border: 'none',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    Open Ustaad Jee on iPhone
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.ustaadjee.school&pli=1',
                        '_blank'
                      )
                    }
                    style={{
                      backgroundColor: '#34a853',
                      color: '#fff',
                      padding: '20px 30px',
                      fontSize: '18px',
                      border: 'none',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    Open Ustaad Jee on Android
                  </button>
                </div>
              </>
            )}
            {selectedContent === 'Home' && (
              <>
                <Home
                  showSubjectUnits={showSubjectUnits}
                  setShowSubjectUnits={setShowSubjectUnits}
                  userId={user.lw_id}
                  showMyGrades={showMyGrades}
                  setShowMyGrades={setShowMyGrades}
                  eligible={isEligible()}
                />
              </>
            )}
            {selectedContent === 'Help' && (
              <>
                <Help />
              </>
            )}
          </div>
        </div>
      )}

      <footer
        style={{
          textAlign: 'center',
          // marginTop: '10px',
          padding: '10px 0',
          backgroundColor: '#f8f9fa',
          color: '#6c757d',
          fontSize: '14px',
          borderTop: '1px solid #e9ecef',
        }}
      >
        © {new Date().getFullYear()} Ustaad Jee. All rights reserved.
      </footer>
    </div>
  )
}

export default App
