import React, { useEffect, useState } from 'react'
import './UnitDetailsModal.css'
import feedbackMessages from './feedbackMessages.json'
import { FaTrophy, FaExclamationTriangle } from 'react-icons/fa'

const UnitDetailsModal = ({ unitName, unitDetails, unitScore, onClose }) => {
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [feedbackIcon, setFeedbackIcon] = useState(null)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const topicsPerPage = 5

  useEffect(() => {
    if (unitScore) {
      const average_score = unitScore

      let randomMessage = ''
      let icon = null
      if (average_score >= 95) {
        const randomIndex = Math.floor(
          Math.random() * feedbackMessages.above95Unit.length
        )
        randomMessage = feedbackMessages.above95Unit[randomIndex]
        icon = <FaTrophy size={30} color='gold' />
      } else {
        const randomIndex = Math.floor(
          Math.random() * feedbackMessages.below95Unit.length
        )
        randomMessage = feedbackMessages.below95Unit[randomIndex]
        icon = <FaExclamationTriangle size={30} color='red' />
      }

      setFeedbackMessage(randomMessage)
      setFeedbackIcon(icon)
    }
  }, [unitScore])

  // Sort topics
  const sortedTopics = Object.entries(unitDetails).sort(
    ([, aDetails], [, bDetails]) => {
      if (aDetails.exam_type < bDetails.exam_type) return -1
      if (aDetails.exam_type > bDetails.exam_type) return 1
      return bDetails.average_score - aDetails.average_score
    }
  )

  // Pagination logic
  const indexOfLastTopic = currentPage * topicsPerPage
  const indexOfFirstTopic = indexOfLastTopic - topicsPerPage
  const currentTopics = sortedTopics.slice(indexOfFirstTopic, indexOfLastTopic)

  const totalPages = Math.ceil(sortedTopics.length / topicsPerPage)

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1)
  }

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1)
  }

  return (
    <>
      <div className='modal-overlay'>
        <div className='modal-content'>
          <h3>
            {unitName} ({unitScore}%)
          </h3>
          <div className='modal-body'>
            {currentTopics.map(([topic, details]) => (
              <div key={topic} className='modal-topic'>
                <div className='topic-name'>
                  {topic} ({details.exam_type})
                </div>
                <div className='topic-score'>{details.average_score}%</div>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className='pagination-controls'>
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className='pagination-button'
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className='pagination-button'
            >
              Next
            </button>
          </div>

          {/* Feedback Panel */}
          <div className='feedback-panel'>
            <div className='feedback-icon'>{feedbackIcon}</div>
            <p>{feedbackMessage}</p>
          </div>
          <button className='close-button' onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </>
  )
}

export default UnitDetailsModal
