import React, { useEffect, useState } from 'react'
import axios from 'axios'
import UnitDetailsModal from './UnitDetailsModal'
import './SubjectUnits.css'
import MyGrades from './MyGrades'

// Import feedback messages
import feedbackMessages from './feedbackMessages.json'

// Import icons from react-icons
import { FaTrophy, FaExclamationTriangle } from 'react-icons/fa'

const CACHE_KEY = 'subjectUnits.data.'
const CACHE_TIMESTAMP_KEY = 'subjectUnits.cacheTimestamp.'

const SubjectUnits = ({
  subject,
  userId,
  score,
  color,
  showSubjectUnits,
  setShowSubjectUnits,
}) => {
  const [units, setUnits] = useState(null)
  const [unitDetails, setUnitDetails] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [feedbackIcon, setFeedbackIcon] = useState(null)

  useEffect(() => {
    const fetchUnits = async () => {
      const cacheExpirationMinutes =
        parseInt(process.env.REACT_APP_CACHE_EXPIRATION_MINUTES, 10) || 15
      const now = new Date().getTime()

      // Update keys
      const cacheKey = CACHE_KEY + subject + userId
      const cacheTSKey = CACHE_TIMESTAMP_KEY + subject + userId

      // Check local storage for cached data
      const cachedData = localStorage.getItem(cacheKey)
      const cachedTimestamp = localStorage.getItem(cacheTSKey)

      if (cachedData && cachedTimestamp) {
        const elapsedMinutes =
          (now - parseInt(cachedTimestamp, 10)) / (1000 * 60)

        // Use cached data if within expiration time
        if (elapsedMinutes < cacheExpirationMinutes) {
          setUnits(JSON.parse(cachedData))
          return
        }
      }

      try {
        const response = await axios.get(
          `https://ush.physicsinseconds.com/${subject}/unit_aggregates/${userId}`
        )
        setUnits(response.data)
        // Store response and timestamp in local storage
        localStorage.setItem(cacheKey, JSON.stringify(response.data))
        localStorage.setItem(cacheTSKey, now.toString())
      } catch (error) {
        console.error('Error fetching units:', error)
      }
    }

    fetchUnits()
  }, [subject, userId])

  useEffect(() => {
    if (score) {
      const average_score = score

      // Randomly select a message when grades or average_score changes
      let randomMessage = ''
      let icon = null
      if (average_score >= 95) {
        // Select random feedback from "above95" category
        const randomIndex = Math.floor(
          Math.random() * feedbackMessages.above95Subject.length
        )
        randomMessage = feedbackMessages.above95Subject[randomIndex]
        icon = <FaTrophy size={30} color='gold' /> // Trophy icon for high scores
      } else {
        // Select random feedback from "below95" category
        const randomIndex = Math.floor(
          Math.random() * feedbackMessages.below95Subject.length
        )
        randomMessage = feedbackMessages.below95Subject[randomIndex]
        icon = <FaExclamationTriangle size={30} color='red' /> // Warning icon for below 95%
      }

      setFeedbackMessage(randomMessage)
      setFeedbackIcon(icon)
    }
  }, [score]) // Re-run this effect whenever grades are fetched or updated

  const fetchUnitDetails = async (unit) => {
    const cacheExpirationMinutes =
      parseInt(process.env.REACT_APP_CACHE_EXPIRATION_MINUTES, 10) || 15
    const now = new Date().getTime()

    // Update keys
    const cacheKey = CACHE_KEY + subject + unit + userId
    const cacheTSKey = CACHE_TIMESTAMP_KEY + subject + unit + userId

    // Check local storage for cached data
    const cachedData = localStorage.getItem(cacheKey)
    const cachedTimestamp = localStorage.getItem(cacheTSKey)

    if (cachedData && cachedTimestamp) {
      const elapsedMinutes = (now - parseInt(cachedTimestamp, 10)) / (1000 * 60)

      // Use cached data if within expiration time
      if (elapsedMinutes < cacheExpirationMinutes) {
        setUnitDetails(JSON.parse(cachedData))
        setSelectedUnit(unit)
        return
      }
    }

    try {
      const response = await axios.get(
        `https://ush.physicsinseconds.com/${subject}/unit_exam_type_aggregates/${userId}/${unit}`
      )
      setUnitDetails(response.data)
      setSelectedUnit(unit)
      // Store response and timestamp in local storage
      localStorage.setItem(cacheKey, JSON.stringify(response.data))
      localStorage.setItem(cacheTSKey, now.toString())
    } catch (error) {
      console.error('Error fetching unit details:', error)
    }
  }

  const handleCloseModal = () => {
    setUnitDetails(null)
    setSelectedUnit(null)
  }

  if (!units) {
    return <div>Loading...</div>
  }

  return (
    <>
      {!showSubjectUnits.flag ? (
        (<MyGrades
          showSubjectUnits={showSubjectUnits}
          setShowSubjectUnits={setShowSubjectUnits}
          userId={userId}
        />)()
      ) : (
        <>
          {/* Feedback Panel */}
          <div className='feedback-panel'>
            <div className='feedback-icon'>{feedbackIcon}</div>
            <p>{feedbackMessage}</p>
          </div>
          <div>
            {/* Display subject name color={`var(--flp-color)`} */}
            <div className='subject-title' style={{ backgroundColor: color }}>
              <div className='score-text'>
                {subject === 'logical' ? 'Logical Reasoning' : subject}
              </div>
              {/* Display subject name */}
              <div className='percentage'>{score}%</div>{' '}
              {/* Highlight the score */}
            </div>

            {/* Units grid */}
            <div className='units-grid'>
              {Object.keys(units).map((unit, index) => (
                <div
                  key={unit}
                  className={`unit-card ${index % 2 === 0 ? 'even' : 'odd'}`}
                  onClick={() => fetchUnitDetails(unit)}
                >
                  <div className='unit-name'>{unit}</div>
                  <div className='unit-score'>{units[unit].average_score}%</div>
                </div>
              ))}
            </div>
            <button
              className='close-button'
              style={{
                backgroundColor: '#f29f22',
                padding: '15px',
                fontSize: '1.2rem',
              }}
              onClick={() => {
                setShowSubjectUnits({ flag: false, subject: '' })
              }}
            >
              Back
            </button>

            {/* Render modal when a unit is selected */}
            {unitDetails && selectedUnit && (
              <UnitDetailsModal
                unitName={selectedUnit}
                unitDetails={unitDetails}
                unitScore={units[selectedUnit].average_score}
                onClose={handleCloseModal}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default SubjectUnits
