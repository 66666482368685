import React from 'react'
import MyGrades from './MyGrades'

const Home = ({
  showSubjectUnits,
  setShowSubjectUnits,
  userId,
  showMyGrades,
  setShowMyGrades,
  eligible,
}) => {
  // const handlePollClick = (subject) => {
  //   alert(`You voted for ${subject}!`)
  // }

  return (
    <>
      {showMyGrades && (
        <MyGrades
          showSubjectUnits={showSubjectUnits}
          setShowSubjectUnits={setShowSubjectUnits}
          userId={userId}
        />
      )}
      {!showMyGrades && (
        <div
          style={{
            padding: '20px',
            textAlign: 'center',
            // height: '100vh',
          }}
        >
          {/* Heading */}
          <h1>Welcome to My Ustaad Jee</h1>
          <p>
            Your personalized platform for academic growth and learning support.
          </p>

          {/* Quick Access Buttons */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '15px',
              marginTop: '20px',
            }}
          >
            <button
              style={styles.buttonBlue}
              onClick={() =>
                window.open('https://live.physicsinseconds.com', '_blank')
              }
            >
              Go to Live Classes
            </button>
            <button
              style={styles.buttonGreen}
              onClick={() => {
                if (eligible) setShowMyGrades(true)
              }}
            >
              Explore My Grades
            </button>
            <button
              style={styles.buttonYellow}
              onClick={() =>
                window.open('https://physicsinseconds.com/social', '_blank')
              }
            >
              Ask Ustaad Jee
            </button>
          </div>

          {/* Motivational Quote */}
          <blockquote style={styles.quote}>
            <p style={styles.quoteText}>
              The beautiful thing about learning is that no one can take it away
              from you.
            </p>
            <footer style={styles.quoteAuthor}>B.B. King</footer>
          </blockquote>

          {/* User Progress Summary */}
          {localStorage.getItem('userProgress.averageScore') && (
            <div style={styles.progressBox}>
              <h3 style={styles.progressBoxHeader}>Your Progress</h3>
              <p style={styles.progressBoxContent}>
                🎯 Overall Average Score:{' '}
                {localStorage.getItem('userProgress.averageScore')}%
              </p>
              <p style={styles.progressBoxContent}>
                {localStorage.getItem('userProgress.averageScore') >= 95 ? (
                  <span style={styles.progressBoxEmoji}>🏆</span> // Trophy emoji for high scores
                ) : (
                  <span style={styles.progressBoxEmoji}>💪</span> // Flexed biceps emoji to motivate
                )}{' '}
                {localStorage.getItem('userProgress.feedbackMessage')}
              </p>
            </div>
          )}

          {/* Announcements Section */}
          <div style={styles.announcementContainer}>
            <div style={styles.announcementBox}>
              <h3 style={styles.announcementHeader}>Latest Announcements</h3>
              <ul style={styles.announcementList}>
                <li>MDCAT Uraan Session classes are currently underway!</li>
                <li>Join our free FSC live classes on our YouTube channel!</li>
                <li>
                  Check out the latest tips and tricks now available on the
                  Physics In Seconds YouTube channel!
                </li>
              </ul>
            </div>
          </div>
          {/* Interactive Poll */}
          {/* <div style={{ margin: '20px auto', textAlign: 'center' }}>
        <h4>Quick Poll: What subject do you need the most help with?</h4>
        {['Physics', 'Chemistry', 'Biology'].map((subject) => (
          <button
            key={subject}
            style={styles.pollButton}
            onClick={() => handlePollClick(subject)}
          >
            {subject}
          </button>
        ))}
      </div> */}

          {/* Footer */}
          <footer
            style={{
              marginTop: '20px',
              textAlign: 'center',
              color: '#777',
              fontSize: '0.9rem',
              // padding: '1px',
            }}
          >
            <p
              style={{
                color: '#007BFF',
                textDecoration: 'none',
              }}
            >
              Need help? Visit the Help Section.
            </p>
          </footer>
        </div>
      )}
    </>
  )
}

// Styles
const styles = {
  buttonBlue: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  buttonGreen: {
    padding: '10px 20px',
    backgroundColor: '#28A745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  buttonYellow: {
    padding: '10px 20px',
    backgroundColor: '#FFC107',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
  },

  announcementContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh', // Full height of the viewport
    // backgroundColor: '#f0f0f0', // Light background color for the whole screen
  },
  announcementBox: {
    border: '2px solid #4caf50', // Subtle green border for freshness
    borderRadius: '10px',
    padding: '20px',
    margin: '20px auto',
    backgroundColor: '#f4fff4', // Light greenish background for warmth
    textAlign: 'left',
    maxWidth: '70vh',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
  },
  announcementHeader: {
    fontSize: '1.5rem',
    color: '#333',
    borderBottom: '2px solid #4caf50',
    paddingBottom: '10px',
    marginBottom: '15px',
  },
  announcementList: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    lineHeight: '1.8', // Better readability
    fontSize: '1.1rem',
    color: '#555',
  },
  progressBox: {
    border: '1px solid #007BFF',
    borderRadius: '10px',
    padding: '20px',
    margin: '20px auto',
    color: '#007BFF',
    maxWidth: '450px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#f1f8ff', // Light blue background for a soft look
    boxShadow: '0 4px 12px rgba(0, 123, 255, 0.1)', // Soft shadow for depth
    fontFamily: "'Arial', sans-serif", // Better typography
    textAlign: 'center', // Centering the text
    lineHeight: '1.6',
  },
  progressBoxHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#007BFF',
  },
  progressBoxContent: {
    fontSize: '1.2rem',
    margin: '10px 0',
    color: '#333', // Darker text for readability
  },
  progressBoxEmoji: {
    fontSize: '1.5rem', // Increase emoji size for better visibility
  },
  pollButton: {
    margin: '5px',
    padding: '10px 15px',
    backgroundColor: '#6c63ff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  quote: {
    borderLeft: '5px solid #4caf50', // Green accent for the quote
    padding: '15px 20px',
    margin: '20px auto',
    backgroundColor: '#f9f9f9',
    fontStyle: 'italic',
    color: '#333',
    maxWidth: '600px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
  },
  quoteText: {
    margin: 0,
    fontSize: '1.2rem',
    lineHeight: '1.6',
  },
  quoteAuthor: {
    marginTop: '10px',
    fontSize: '1rem',
    textAlign: 'right',
    fontWeight: 'bold',
    color: '#4caf50', // Matches the green border
  },
}

export default Home
